
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
  export default class RulesValidation extends Vue {
  @Prop({ type: String, default: '' }) password!: string;
  upperCaseValidation = /(?=.*[A-Z])/;
  numberValidation = /(?=.*\d)/;
  specialValidation = /[^A-Za-z 0-9]/;
  lowerCaseValidation = /(?=.*[a-z])/;
  notWhiteSpace = /\s/;

  get lowerCase (): boolean {
    return this.lowerCaseValidation.test(this.password)
  }

  get upperCase (): boolean {
    return this.upperCaseValidation.test(this.password)
  }

  get numberExist (): boolean {
    return this.numberValidation.test(this.password)
  }

  get special (): boolean {
    return this.specialValidation.test(this.password)
  }

  get minimumCharacters (): boolean {
    return this.password.length >= 8
  }

  get whiteSpace (): boolean {
    return !this.notWhiteSpace.test(this.password)
  }

  get validation (): boolean {
    return (
      this.lowerCase &&
      this.upperCase &&
      this.numberExist &&
      this.special &&
      this.minimumCharacters &&
      this.whiteSpace
    )
  }

  @Watch('validation') onChangeValidation (value: boolean): void {
    this.$emit('password-validation', value)
  }
  }


  import { Component, Vue } from 'vue-property-decorator'
  import firebase from 'firebase/app'
  import 'firebase/auth'
  import 'firebaseui/dist/firebaseui.css'
  import { FIREBASE_ERROR, TEXT_SIZE_H3, TEXT_SIZE_H4 } from '@/utils/general'
  import { NotificationContent } from '@/store/notification/state'
  import { mapActions, mapGetters } from 'vuex'
  import RulesValidation from '@/components/core/input/RulesValidation.vue'
  import i18n from '@/plugins/i18n'
  import { RULES } from '@/components/forms'

@Component({
  components: { RulesValidation },
  methods: {
    ...mapActions('notification', ['loadNotification']),
  },
  computed: {
    ...mapGetters('app', ['isMobile']),
  },
})
  export default class Login extends Vue {
  $refs!: {
    form: HTMLFormElement;
    formEmail: HTMLFormElement;
  };

  isMobile!: boolean;
  path = require('@/assets/Logotipo_Interno.svg');
  approvedPassword = false;
  emailRules = [...RULES.isEmail, ...RULES.isRequired];
  passwordRules = RULES.isPassword;
  persistentSession = true;
  show = false;
  form = false;
  formEmail = false;
  register = false;
  email = '';
  password = '';
  confirmPassword = '';
  textSizeH4 = TEXT_SIZE_H4;
  textSizeH3 = TEXT_SIZE_H3;
  loadNotification!: (obj: NotificationContent) => Promise<void>;
  forgotPassword = false;
  loading = false

  get primaryLabel (): string {
    return this.register ? 'sign_up' : 'start_session'
  }

  get secondaryLabel (): string {
    return this.register ? 'sign_up' : 'sign_in'
  }

  get signInUpLabel (): string {
    return this.register ? 'sign_in_button' : 'sign_up_button'
  }

  get signInUpText (): string {
    return this.register ? 'sign_in_text' : 'sign_up_text'
  }

  async signUp (): Promise<void> {
    if (!this.form || !this.$refs.form.validate()) {
      await this.loadNotification({
        message: `Debe Llenar todos los campos requeridos!!!`,
        notificationType: 'error',
      })
      return
    } else if (this.password !== this.confirmPassword) {
      await this.loadNotification({
        message: `Las contraseñas son diferentes`,
        notificationType: 'error',
      })
      return
    } else if (!this.approvedPassword) {
      await this.loadNotification({
        message: `La contraseña no cumple los requisitos minimos`,
        notificationType: 'error',
      })
      return
    }
    this.loading = true
    try {
      await firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
    } catch (e) {
      this.loading = false
      await this.loadNotification({
        // @ts-ignore catch must be any or unknown interface
        message: `${i18n.t(FIREBASE_ERROR[e.code])}`,
        notificationType: 'error',
      })
    }
  }

  async signIn (): Promise<void> {
    if (!this.form || !this.$refs.form.validate()) {
      await this.loadNotification({
        message: `Debe Llenar todos los campos requeridos!!!`,
        notificationType: 'error',
      })
      return
    }
    this.loading = true
    try {
      await firebase
        .auth()
        .setPersistence(
          this.persistentSession
            ? firebase.auth.Auth.Persistence.LOCAL
            : firebase.auth.Auth.Persistence.SESSION
        )
      await firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
    } catch (e) {
      this.loading = false
      await this.loadNotification({
        // @ts-ignore catch must be any or unknown interface
        message: `${i18n.t(FIREBASE_ERROR[e.code])}`,
        notificationType: 'error',
      })
    }
  }

  async forgotPass (): Promise<void> {
    if (!this.email) {
      await this.loadNotification({
        message: `Debe Ingresar un email!`,
        notificationType: 'warning',
      })

      this.$refs.formEmail.validate()
      return
    }
    try {
      await firebase.auth().sendPasswordResetEmail(this.email)
      await this.loadNotification({
        message: `Por Favor revise su bandeja de entrada`,
        notificationType: 'success',
      })
      this.forgotPassword = false
    } catch (e) {
      await this.loadNotification({
        // @ts-ignore catch must be any or unknown interface
        message: `${i18n.t(FIREBASE_ERROR[e.code])}`,
        notificationType: 'error',
      })
    }
  }

  async signInGoogle (): Promise<void> {
    try {
      const provider = new firebase.auth.GoogleAuthProvider()
      await firebase.auth().signInWithPopup(provider)
    } catch (e) {
      await this.loadNotification({
        // @ts-ignore catch must be any or unknown interface
        message: `${i18n.t(FIREBASE_ERROR[e.code])}`,
        notificationType: 'error',
      })
    }
  }

  redirectTo (): void {
    this.$router.push({ name: 'home' })
  }

  rulesApproved (value: boolean): void {
    this.approvedPassword = value
  }
  }
